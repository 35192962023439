import ReducerRegistry from "../../base/redux/ReducerRegistry";
import { ACTION_TYPES } from "./actionTypes";

const initState = {
  posts: {
    status: "",
    data: [],
    total: 0
  },
};

ReducerRegistry.register("post", (state = initState, action) => {
  switch (action.type) {

    case ACTION_TYPES.GET_POSTS: {
      return {
        ...state,
        posts: {
          ...state.posts,
          ...action.payload,
        },
      };
    }

    case ACTION_TYPES.GET_MORE_POSTS: {
      let posts = { ...state.posts };
      action.payload.data.map(item => posts.data.push(item));
      return {
        ...state,
        posts: posts
      };
    }
    default:
      return state;
  }
});

import React, { Suspense } from "react";
const PostDetailWrapper = React.lazy(() => import("../components/PostDetailWrapper"));

const PostDetailPage = () => {
    return (
        <Suspense fallback={<></>}>
            <PostDetailWrapper />
        </Suspense>
    );
};

export default PostDetailPage;

import { ACTION_TYPES } from "./actionTypes";
import { CacheService } from "./services";
import { COMPONENT_STATUS } from "../../common";
import moment, { now } from "moment";

import toastr from "toastr";
export const setAuth = (auth) => {
  return { type: ACTION_TYPES.SET_AUTH, auth };
};

export const getAdvertisements = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ADVERTISEMENTS,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiGetAdvertisements()
      .then((res) => {
        let viewedAdIds = JSON.parse(localStorage.getItem('viewed_ad_ids'));
        viewedAdIds = viewedAdIds ? viewedAdIds : [];
        let advertisements = [];
        advertisements = res.data.filter(item => {
          let pass = true;
          if (viewedAdIds.includes(item.id)) {
            pass = false;
          }
          if (item.start_date && moment(item.start_date).diff(moment()) > 0) {
            pass = false;
          }
          if (item.end_date && moment(item.end_date).diff(moment()) < 0) {
            pass = false;
          }
          return pass;
        });

        dispatch({
          type: ACTION_TYPES.GET_ADVERTISEMENTS,
          payload: {
            status: COMPONENT_STATUS.SUCCESS,
            data: advertisements,
            total: advertisements.length,
          },
        });

      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_ADVERTISEMENTS,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

export const getNotications = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_NOTIFICATIONS,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiGetNotifications()
      .then((res) => {
        if (res && res.code == 200) {
          dispatch({
            type: ACTION_TYPES.GET_NOTIFICATIONS,
            payload: {
              status: COMPONENT_STATUS.SUCCESS,
              data: res.data,
              total: res.count,
            },
          });
        } else {
          dispatch({
            type: ACTION_TYPES.GET_NOTIFICATIONS,
            payload: { status: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.GET_NOTIFICATIONS,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};
export const updateCmsSetting = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_CMS_SETTING,
      payload: { name, value },
    });
  };
};

export const updateWebsiteInfo = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
      payload: { status: COMPONENT_STATUS.LOADING },
    });
    CacheService.apiUpdateWebsiteInfo(params)
      .then((res) => {
        if (res && res.id > 0) {
          toastr.success("Cập nhật thành công");
          dispatch({
            type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
            payload: { status: COMPONENT_STATUS.SUCCESS, data: params },
          });
        } else {
          toastr.error("Cập nhật thất bại");
          dispatch({
            type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
            payload: { status: COMPONENT_STATUS.ERROR },
          });
        }
      })
      .catch((error) => {
        toastr.error("Cập nhật thất bại");
        dispatch({
          type: ACTION_TYPES.UPDATE_WEBSITE_INFO,
          payload: { status: COMPONENT_STATUS.ERROR },
        });
      });
  };
};

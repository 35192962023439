import React, { Suspense } from "react";
const PostsWrapper = React.lazy(() => import("../components/PostsWrapper"));

const PostsPage = () => {
  return (
    <Suspense fallback={<></>}>
      <PostsWrapper />
    </Suspense>
  );
};

export default PostsPage;

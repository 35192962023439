import { Api } from "../../common/utils/request";
import { BASE_API_URL, LARAVEL_URL } from "../../common/constants";

const apiGetWebsiteByDomain = (data) => {
  return Api.get(`${BASE_API_URL}/website/get_by_domain/`, data);
};

const apiGetNotifications = () => {
  return Api.get(`${LARAVEL_URL}/notify-transactions`, { watch: 2 });
};

const apiUpdateWebsiteInfo = (data) => {
  return Api.put(`${BASE_API_URL}/website/${data.id}/`, data);
};

const apiGetAdvertisements = (data) => {
  return Api.get(`${BASE_API_URL}/advertisements/list`, data);
};

export const CacheService = {
  apiGetWebsiteByDomain,
  apiGetNotifications,
  apiUpdateWebsiteInfo,
  apiGetAdvertisements
};

import moment from "moment";
export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL || "https://admin.airclass.io/api";
export const LARAVEL_URL =
  process.env.REACT_APP_LARAVEL_API_URL || "http://103.195.237.18/api";
export const VCCLOUD_URL = process.env.REACT_APP_VCCLOUD_API_URL;
export const BASE_MEDIA_URL = process.env.REACT_APP_BASE_MEDIA_URL || "";

export const COMPONENT_STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const DIRECTION_SORT = {
  DESC: "DESC",
  ASC: "ASC",
};

export const FORMAT_EXPORT = ".xlsx";
export const DATATIME_FORMAT = "DD/MM/YYYY HH:mm";
export const USER_TYPE = {
  STUDENT: 2,
  TEACHER: 3,
};
export const TRANSACTION_STATUS = {};
export const renderDateTime = (time) => {
  return time ? moment(time).format(DATATIME_FORMAT) : "";
};
export const renderNumber = (number) => {
  return number ? number.toLocaleString() : "";
};

export const nonAccentVietnamese = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
  return str;
};
export const DATA_TYPE = {
  TEXT: "InputText",
  SELECT: "ReactSelect",
  SEARCH: "SearchByKeyword",
  DATE: "DatePicker",
  NUMBER: "InputNumber",
  DATERANGE: "DateRangePicker",
};

export const MEDIA_TYPE_VCCLOUD = {
  IMAGE: 2,
  VIDEO: 3,
};

export const SWIPEABLE_CARD = 'swipeable-card';
export const LUCK_WHEEL = 'lucky-wheel';
export const COUNT_DOWN_TIME = 'count-down-time';
export const SPIN_BOTTLE = 'spin-bottle';
export const TOP_GAME = 'top';
export const GAME_TYPE_SLUG = [SWIPEABLE_CARD, LUCK_WHEEL, COUNT_DOWN_TIME, SPIN_BOTTLE, SPIN_BOTTLE];
export const GAME_GUILE = {
  SWIPEABLE_CARD: [
    { title: 'Chọn ngay một bộ câu hỏi bên dưới để bắt đầu cuộc vui.', image: 'dadadada' },
    { title: '"Bấm 2 lần" vào màn hình để lật lá bài.', image: 'dadadada' },
    { title: 'Làm theo nhiệm vụ mà lá bài đưa ra. Nếu không thực hiện được, mời bạn chịu phạt.', image: 'dadadada' },
    { title: '"Vuốt sang bên" để tiếp tục lượt chơi mới.', image: '' },
    { title: 'Khi bốc đến lá bài cuối cùng, bấm "Chơi lại" nếu bạn muốn tiếp tục hoặc chọn trò chơi mới.', image: '' }
  ],
  LUCK_WHEEL: [
    { title: 'Chọn ngay một vòng quay bên dưới để bắt đầu cuộc vui.', image: 'ddd' },
    { title: 'Bấm "Quay" và chờ đợi nhiệm vụ mà vòng quay mang đến.', image: 'dd' },
    { title: 'Thực hiện nhiệm vụ của bạn.', image: 'ddd' },
    { title: 'Vào "Cài đặt game" để thêm các nhiệm vụ cho game.', image: '' }
  ],
  COUNT_DOWN_TIME: [
    { title: 'Chọn ngay một bộ câu hỏi bên dưới để bắt đầu cuộc vui.', image: 'ddd' },
    { title: 'Quyết định hình phạt. Các bạn muốn phạt gì nào? Tự nghĩ nhé!', image: 'dd' },
    { title: 'Người bắt đầu đọc to câu hỏi, sau đó bấm "Bắt đầu"', image: 'ddd' },
    { title: 'Mỗi người tham gia chơi sẽ lần lượt liệt kê 1 đáp án. Trả lời thật nhanh trong vòng 5s và bấm vào đồng hồ để chuyển lượt trước khi đồng hồ đếm ngược về 0', image: '' },
    { title: 'Lượt chơi sẽ dừng lại khi có người không liệt kê đúng hoặc quá thời gian cho phép và người đó sẽ phải chịu phạt.', image: 'ddd' },
    { title: 'Người thua bấm "Tiếp tục chơi" để bắt đầu lượt chơi mới.', image: 'ddd' },
    { title: 'Vào "Cài đặt game" để thêm các câu hỏi cho game.', image: 'ddd' }
  ]
}
export const MAX_SIZE_VIDEO_UPLOAD = 200;
export const MAX_SIZE_IMAGE_UPLOAD = 5;

export const ADS_POSITION_HOME = 'home';
export const ADS_POSITION_LIST_GAME = 'list_game';
export const ADS_POSITION_PLAYING_GAME = 'playing_game';
export const ADS_POSITION = [
  {
    id: ADS_POSITION_HOME,
    value: ADS_POSITION_HOME,
    label: 'Trang chủ'
  },
  {
    id: ADS_POSITION_LIST_GAME,
    value: ADS_POSITION_LIST_GAME,
    label: 'Danh sách game'
  },
  {
    id: ADS_POSITION_PLAYING_GAME,
    value: ADS_POSITION_PLAYING_GAME,
    label: 'Chơi game'
  },
];
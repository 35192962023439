import React from "react";
import { ExclamationIcon } from "@heroicons/react/outline";
import Modal from "./Modal";
import ButtonAction from "./ButtonAction";
const ModalConfirmDelete = ({
  open,
  setOpen,
  title,
  content,
  actionConfirmDelete = false,
  id,
}) => {
  const doAction = () => {
    if (actionConfirmDelete) {
      actionConfirmDelete(id);
    }
    setOpen(false);
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="p-4">
        <div
          className={`mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100`}
        >
          <ExclamationIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <div className="mt-2">
            <p className="text-sm text-gray-500">{content}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 text-center mb-4">
        <ButtonAction className="btn-cancel w-20 sm:text-sm" actionButton={() => setOpen(false)}>Không</ButtonAction>
        <ButtonAction className="btn-custom w-20 ml-5 sm:text-sm" actionButton={doAction}>Có</ButtonAction>
      </div>
    </Modal>
  );
};

export default React.memo(ModalConfirmDelete);

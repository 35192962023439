import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { getAdvertisements } from "./modules/caches";

import { routes } from "./routes";
import { history, store, persistor } from "./modules/base";
import { AppLayout } from "./modules/layout";
import "./App.scss";
function App() {
  useEffect(() => {
    store.dispatch(getAdvertisements());
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history} basename="/">
          <AppLayout>
            <Switch>
              {routes.map((route, k) => {
                return (
                  <Route
                    exact
                    key={k}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </AppLayout>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

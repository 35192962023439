import React, { Suspense } from "react";
const GamingScreenWrapper = React.lazy(() => import("../components/GamingScreenWrapper"));

const GamingSreenPage = () => {
  return (
    <Suspense fallback={<></>}>
      <GamingScreenWrapper />
    </Suspense>
  );
};

export default GamingSreenPage;

import PostsPage from "./pages/PostsPage";
import PostDetailPage from "./pages/PostDetailPage";

export const postsRoute = [
  {
    path: "/posts",
    component: PostsPage,
  },
  {
    path: "/posts/:id",
    component: PostDetailPage,
  },
];

import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import queryString from 'query-string'

const AppLayout = ({ children }) => {
  const { search } = useLocation();
  const values = queryString.parse(search)
  let backgroundUrl = values.bg_url;
  if (!backgroundUrl) {
    backgroundUrl = 'background.png';
  }
  return (
    <div className="background-app" style={{
      backgroundImage: `url(/${backgroundUrl})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      minHeight: 'calc(100vh)',
      color: "#ffffff",
      paddingTop: "1px"
    }}>
      {children}
    </div>
  );
};

export default AppLayout;

import React, { Suspense } from "react";
const GamesWrapper = React.lazy(() => import("../components/GamesWrapper"));

const GamesPage = () => {
  return (
    <Suspense fallback={<></>}>
      <GamesWrapper />
    </Suspense>
  );
};

export default GamesPage;

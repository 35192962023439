import { homeRoute } from "../modules/home";
import { postsRoute } from "../modules/posts";
import { gamesRoute } from "../modules/games";
import { gameRoute } from "../modules/gamingSreen";
import { ComingSoon } from "../modules/base/components/ComingSoon";
export const routes = [
  ...homeRoute,
  ...postsRoute,
  ...gamesRoute,
  ...gameRoute,
  {
    path: "*",
    component: ComingSoon,
    unauthenticated: true,
  },
];

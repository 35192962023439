import React, { useState } from "react";
import Player from "./Player";

const ButtonAction = ({
    actionButton = false,
    children,
    isFetching,
    size = 20,
    disabled = false,
    className
}) => {
    // let audio = new Audio("/button_click_sound_effect.mp3")
    const doAction = () => {
        // audio.play()

        if (actionButton && !disabled) {
            actionButton();
        }
    };

    return (
        <button
            className={`${className ? className : ''} ${disabled ? "disabled" : ""}`}
            disabled={isFetching || disabled}
            type="button"
            onClick={doAction}
        >
            {children}
        </button>

    );
};
export default React.memo(ButtonAction);

import HomePage from "./pages/HomePage";

export const homeRoute = [
  {
    path: "/home",
    component: HomePage,
  },
  {
    path: "/",
    component: HomePage,
  },
];
